import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDinersClub, FaCcDiscover } from "react-icons/fa";
import { SiPix } from "react-icons/si";
import { FaRulerCombined } from "react-icons/fa"; // Importando o ícone da régua
import ProdutoSugeridoCarrossel from "./ProdutoSugeridoCarrossel";
import "./Produto.css";
import medidas from "./IMG/medidas.png"; // Imagem para o modal

function Produto({ product, onAddToCart, products }) {
  const { productName } = useParams();
  const [mainImage, setMainImage] = useState(product?.imageUrls[0]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showModal, setShowModal] = useState(false); // Estado para controlar o modal
  const [personalizedName, setPersonalizedName] = useState("");
  const [personalizedNumber, setPersonalizedNumber] = useState("");
  const [isPersonalized, setIsPersonalized] = useState(false); // Estado para controlar a personalização

  useEffect(() => {
    if (product && product.imageUrls && product.imageUrls.length > 0) {
      setMainImage(product.imageUrls[0]);
    }
    setSelectedSizes([]);
    window.scrollTo(0, 0);
  }, [product]);
  if (!product) {
    return <div>Produto não encontrado.</div>;
  }

  const handleThumbnailClick = (url) => {
    setMainImage(url);
  };

  const produto = product;
  const tamanhosDisponiveis = Array.isArray(produto.sizes) ? produto.sizes : [];
  const estoque = typeof produto.stock === "object" ? produto.stock : {};

  const toggleSizeSelection = (size) => {
    if (selectedSizes.includes(size)) {
      setSelectedSizes(selectedSizes.filter((selected) => selected !== size));
    } else {
      if (estoque[size] > 0) {
        setSelectedSizes([...selectedSizes, size]);
      }
    }
  };

  const handleAddToCart = () => {
    if (selectedSizes.length === 0) {
      alert("Por favor, selecione pelo menos um tamanho disponível.");
      return;
    }
    selectedSizes.forEach((size) => {
      onAddToCart({ ...produto, selectedSize: size });
    });
    setSelectedSizes([]);
  };

  const randomProducts = products
    .filter((p) => p.id !== produto.id)
    .sort(() => Math.random() - 0.5)
    .slice(0, 8);

  const truncatedDescription =
    produto.description && produto.description.length > 100
      ? `${produto.description.slice(0, 100)}...`
      : produto.description;

  // Função para abrir o modal
  const openModal = () => {
    setShowModal(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setShowModal(false);
  };
  const handlePersonalizationChange = (event) => {
    setIsPersonalized(event.target.checked); // Altera o estado para personalização
  };

  const handleAddToCartWithPersonalization = () => {
    if (!personalizedName || !personalizedNumber) {
      alert("Por favor, preencha o nome e o número da personalização.");
      return;
    }
  
    const productToAdd = {
      ...produto,
      selectedSize: selectedSizes[0], // Garantir que apenas um tamanho seja selecionado
      personalizedName,
      personalizedNumber,
      price: produto.price + 24.99, // Adiciona R$24,99 ao preço

    };
    onAddToCart(productToAdd);
  };

  // Função para otimizar a URL da imagem
  const getOptimizedImageUrl = (url, width = 500, height = 500) => {
    if (url && typeof url === 'string' && url.includes("https://pub-7554c6618b524e1691f81d17d505d92b.r2.dev")) {
      const baseUrl = url.split('?')[0]; // Remove qualquer query string existente
      return `${baseUrl}?w=${width}&h=${height}&format=webp`;
    }
    return url || ''; // Retorna uma string vazia se a URL for undefined
  };

  return (
    <div className="produto-container">
      <div className="produto-detalhes">
        <div className="produto-imagens-adicionais">
        {produto.imageUrls.slice(0, 5).map((url, idx) => (
  <picture key={idx}>
    <source srcSet={getOptimizedImageUrl(url)} type="image/webp" />
    <img
      src={getOptimizedImageUrl(url)}
      alt={produto.name}
      className={`produto-imagem-pequena ${mainImage === url ? "active" : ""}`}
      onClick={() => handleThumbnailClick(url)}
      loading="lazy"
    />
  </picture>
))}

        </div>
        <div className="produto-imagem-maior">
        <picture>
  <source srcSet={getOptimizedImageUrl(mainImage)} type="image/webp" />
  <img src={getOptimizedImageUrl(mainImage)} alt={produto.name} loading="lazy" className="produto-imagem" />
</picture>


        </div>
        <div className="produto-info">
          <h1>{produto.name}</h1>
          <div className="price-container">
            {produto.discountPrice && (
              <span className="old-price">
                {`R$ ${produto.discountPrice.toFixed(2).replace(".", ",")}`}
              </span>
            )}
            <span className="current-price">
              {`R$ ${produto.price.toFixed(2).replace(".", ",")}`}
            </span>
          </div>
          <div className="tamanhos-disponiveis">
            <h3>Tamanhos Disponíveis:</h3>
            <div className="tamanhos">
              {tamanhosDisponiveis.map((size) => (
                <span
                  key={size}
                  className={`tamanho ${
                    selectedSizes.includes(size) ? "selected" : ""
                  } ${estoque[size] <= 0 ? "indisponivel" : ""}`}
                  onClick={() => toggleSizeSelection(size)}
                >
                  {size}
                </span>
              ))}
            </div>
          </div>


      <button
        className="add-to-cart-btn"
        onClick={isPersonalized ? handleAddToCartWithPersonalization : handleAddToCart}
        disabled={selectedSizes.length === 0}
      >
        Adicionar ao Carrinho
      </button>
          {produto.description && (
            <div className="produto-descricao">
              <p>
                {showFullDescription
                  ? produto.description
                  : truncatedDescription}
              </p>
              {produto.description.length > 100 && (
                <button
                  className="ver-mais-btn"
                  onClick={() => setShowFullDescription(!showFullDescription)}
                >
                  {showFullDescription
                    ? "Ver menos"
                    : "Ver mais detalhes do produto"}
                </button>
              )}
            </div>
          )}

          <div className="formas-pagamento">
            <p>Formas de pagamento:</p>
            <div className="icones-pagamento">
              <FaCcVisa className="icone-pagamento" />
              <FaCcMastercard className="icone-pagamento" />
              <FaCcAmex className="icone-pagamento" />
              <FaCcDinersClub className="icone-pagamento" />
              <FaCcDiscover className="icone-pagamento" />
              <SiPix className="icone-pagamento" />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "40px" }}>
        <ProdutoSugeridoCarrossel products={randomProducts} />
      </div>

      {/* Modal para a Tabela de Medidas */}
      {showModal && (
  <div className="modal" onClick={closeModal}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <button className="close-medidas" onClick={closeModal}>
        &times;
      </button>
      <img src={medidas} alt="Tabela de Medidas" />
    </div>
  </div>
)}

    </div>
  );
}

export default Produto;
