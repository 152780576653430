import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Checkout.css";
import InputMask from "react-input-mask";
import axios from "axios";
import { db } from "./firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Checkout = ({ cartItems }) => {
  const [step, setStep] = useState(1);
  const [customerData, setCustomerData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    cpf: "",
    postcode: "",
    address_street: "",
    address_street_number: "",
    address_street_complement: "",
    address_street_district: "",
    address_city: "",
    address_state: "",
  });
  const [orderData, setOrderData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [freightType, setFreightType] = useState(null);
  const [shippingCost, setShippingCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [creditCardData, setCreditCardData] = useState({
    number: "",
    cvv: "",
    month: "",
    year: "",
    document_number: customerData.cpf,
    name: "",
    installments: 1,
  });
  const [pixData, setPixData] = useState(null);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const navigate = useNavigate();

  const checkOrderStatus = async (orderId) => {
    try {
      console.log("Verificando status do pedido com ID:", orderId); // Log para verificar o ID
      const orderRef = doc(db, "orders", String(orderId)); // Converte orderId para string
      const orderDoc = await getDoc(orderRef);

      if (orderDoc.exists()) {
        const status = orderDoc.data().paymentDetails.status;
        console.log("Status do pagamento:", status);
        if (status === "paid") {
          setPaymentConfirmed(true);
        }
      }
    } catch (error) {
      console.error("Erro ao verificar o status do pedido:", error);
    }
  };

  useEffect(() => {
    if (paymentMethod === "pix" && pixData && orderData) {
      const statusInterval = setInterval(() => {
        checkOrderStatus(orderData.order_id);
      }, 5000); // Verifica a cada 5 segundos

      if (paymentConfirmed) {
        clearInterval(statusInterval);
      }

      return () => clearInterval(statusInterval); // Limpa o intervalo ao desmontar
    }
  }, [paymentMethod, pixData, orderData, paymentConfirmed]);

  // Rola para o topo sempre que o step mudar
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData({ ...customerData, [name]: value });
  };

  const handleCreditCardInputChange = (e) => {
    const { name, value } = e.target;
    setCreditCardData({ ...creditCardData, [name]: value });
  };

  const shippingRates = {
    SP: 40,
    MG: 50,
    RJ: 50,
    ES: 55,
    PR: 55,
    SC: 60,
    RS: 65,
    MS: 65,
    GO: 70,
    DF: 70,
    MT: 75,
    BA: 75,
    SE: 80,
    AL: 80,
    PE: 85,
    PB: 85,
    RN: 90,
    CE: 90,
    PI: 95,
    MA: 95,
    TO: 100,
    PA: 100,
    AP: 110,
    AM: 110,
    RR: 120,
    RO: 120,
    AC: 130,
  };

  const calculateShippingCost = (state, type) => {
    if (type === "ouro-branco") return 0; // Frete grátis para Ouro Branco
    const baseRate = shippingRates[state] || 0;
    if (type === "pac") return baseRate + 10;
    if (type === "sedex") return baseRate + 15;
    return baseRate; // frete padrão
  };

  const handleInstallmentsChange = (e) => {
    const selectedInstallments = parseInt(e.target.value);
    setCreditCardData({
      ...creditCardData,
      installments: selectedInstallments,
    });
  };
  // Função para buscar o endereço com base no CEP
  useEffect(() => {
    const fetchAddress = async () => {
      const cep = customerData.postcode.replace(/\D/g, ""); // Remove caracteres não numéricos
      if (cep.length === 8) {
        // Verifica se o CEP tem 8 dígitos
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${cep}/json/`
          );
          const { logradouro, bairro, localidade, uf } = response.data;
          setCustomerData((prevData) => ({
            ...prevData,
            address_street: logradouro,
            address_street_district: bairro,
            address_city: localidade,
            address_state: uf,
          }));
        } catch (error) {
          console.error("Erro ao buscar o endereço:", error);
        }
      }
    };

    fetchAddress();
  }, [customerData.postcode]);
  // Atualiza a função calculateTotal para considerar o shippingCost
  const calculateTotal = () => {
    const productTotal = cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );

    const discount = paymentMethod === "pix" ? 0.05 : 0; // Aplica 5% de desconto para Pix
    const totalWithDiscount = productTotal * (1 - discount);

    return totalWithDiscount + (shippingCost || 0); // Garante que shippingCost esteja definido
  };

  const calculateInstallmentValue = (totalAmount, installments) => {
    return (totalAmount / installments).toFixed(2).replace(".", ",");
  };

  const calculateTotalWithInterest = () => {
    const productTotal = cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  
    const interestRate = calculateInterest(creditCardData.installments);
    const interestAmount = (productTotal * interestRate) / 100;  // Calcula os juros com base no valor total
  
    return productTotal + shippingCost + interestAmount;  // Retorna o total com juros
  };  
  
  const calculateInterest = (installments) => {
    const interestRates = [
      0,      // 1x
      4.38,   // 2x
      6.57,   // 3x
      8.76,   // 4x
      10.95,  // 5x
      13.14,  // 6x
      15.33,  // 7x
      17.52,  // 8x
      19.71,  // 9x
      21.90,  // 10x
      24.09,  // 11x
      26.28,  // 12x
    ];
    
    return interestRates[installments - 1] || 0;  // Retorna 0 se não encontrar a taxa de juros
  };
  // Pré-calcula os valores das parcelas com base na tabela de juros
const generateInstallmentOptions = () => {
  const total = calculateTotal(); // Total sem juros
  const interestRates = [
    0,      // 1x
    4.38,   // 2x
    6.57,   // 3x
    8.76,   // 4x
    10.95,  // 5x
    13.14,  // 6x
    15.33,  // 7x
    17.52,  // 8x
    19.71,  // 9x
    21.90,  // 10x
    24.09,  // 11x
    26.28,  // 12x
  ];

  // Retorna um array com as opções de parcelas e seus valores fixos
  return interestRates.map((rate, index) => {
    const installments = index + 1;
    const totalWithInterest = total * (1 + rate / 100); // Aplica os juros
    const installmentValue = totalWithInterest / installments; // Valor por parcela
    return {
      installments,
      installmentValue: installmentValue.toFixed(2).replace(".", ","),
    };
  });
};

// Gere as opções ao renderizar o componente
const installmentOptions = generateInstallmentOptions();


  const handleNextStep = async () => {
    if (step === 1) {
      setLoading(true);

      try {
        console.log("Criando cliente na Appmax...");
        const customerResponse = await fetch(
          "https://admin.appmax.com.br/api/v3/customer",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              "access-token": "94F37DBC-8063ACAB-FDEE1F63-77900A06",
              firstname: customerData.firstname,
              lastname: customerData.lastname,
              email: customerData.email,
              telephone: customerData.telephone,
              postcode: customerData.postcode,
              address_street: customerData.address_street,
              address_street_number: customerData.address_street_number,
              address_street_complement: customerData.address_street_complement,
              address_street_district: customerData.address_street_district,
              address_city: customerData.address_city,
              address_state: customerData.address_state,
              ip: "127.0.0.1",
            }),
          }
        );

        const customerDataResponse = await customerResponse.json();
        if (!customerResponse.ok)
          throw new Error(
            customerDataResponse.message || "Erro ao criar o cliente."
          );

        const customer_id = customerDataResponse.data.id;
        setOrderData({ customer_id });
        setStep(2);
      } catch (error) {
        console.error("Erro ao criar o cliente:", error);
        alert("Erro ao avançar para o próximo passo. Tente novamente.");
      } finally {
        setLoading(false);
      }
    } else if (step === 2) {
      if (!freightType) {
        alert("Por favor, selecione um método de envio.");
        return;
      }

      setLoading(true);

      try {
        console.log("Criando pedido na Appmax...");
        cartItems.forEach((item) => {
          console.log(
            `Produto: ${item.name}, Quantidade: ${
              item.quantity
            }, Preço: R$ ${item.price.toFixed(2).replace(".", ",")}, Tamanho: ${
              item.selectedSize
            }`
          );
        });

        const orderResponse = await fetch(
          "https://admin.appmax.com.br/api/v3/order",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              "access-token": "94F37DBC-8063ACAB-FDEE1F63-77900A06",
              customer_id: orderData.customer_id,
              products: cartItems.map((item) => ({
                sku: item.selectedSize || "DEFAULT-SKU", // Aqui
                name: item.name,
                price: item.price,
                qty: item.quantity,
              })),
              freight_type: freightType,
              shipping: shippingCost,
            }),
          }
        );

        const orderDataResponse = await orderResponse.json();
        if (!orderResponse.ok)
          throw new Error(
            orderDataResponse.message || "Erro ao criar o pedido."
          );

        setOrderData((prevState) => ({
          ...prevState,
          order_id: orderDataResponse.data.id,
        }));
        setStep(3);
      } catch (error) {
        console.error("Erro ao criar o pedido:", error);
        alert("Erro ao avançar para o próximo passo. Tente novamente.");
      } finally {
        setLoading(false);
      }
    }
  };

    // Função para verificar se o valor total do pedido é elegível para frete grátis
    const isFreeShippingEligible = () => {
      const productTotal = cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
      return productTotal > 1000; // Retorna true se o total dos produtos for maior que 1000
    };
  
    // Função para calcular o custo de envio
    const handleFreightTypeChange = (method) => {
      const state = customerData.address_state;
      let cost = 0;
    
      if (method === "ouro-branco") {
        cost = 0; // Frete grátis para Ouro Branco
      } else if (isFreeShippingEligible()) {
        cost = 0; // Frete grátis para compras acima de 1000
      } else {
        cost = calculateShippingCost(state, method);
      }
    
      setFreightType(method);
      setShippingCost(cost); // Atualiza o shippingCost imediatamente
    };

  const updateProductStock = async (cartItems) => {
    try {
      for (const item of cartItems) {
        const productRef = doc(db, "products", item.id); // Supondo que cada item tenha um ID correspondente ao documento do produto no Firestore

        // Recupera os dados atuais do produto
        const productSnapshot = await getDoc(productRef);
        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();

          // Atualiza o estoque para o tamanho selecionado
          const updatedStock = { ...productData.stock };
          updatedStock[item.selectedSize] -= item.quantity;

          // Atualiza o documento no Firestore com o novo estoque
          await setDoc(productRef, { stock: updatedStock }, { merge: true });
          console.log(
            `Estoque atualizado para o produto ${item.name}, tamanho ${item.selectedSize}`
          );
        } else {
          console.log("Produto não encontrado no Firestore");
        }
      }
    } catch (error) {
      console.error("Erro ao atualizar o estoque:", error);
    }
  };

  const saveOrderToFirestore = async (
    orderData,
    customerData,
    cartItems,
    paymentData
  ) => {
    try {
      const orderPayload = {
        customer: {
          firstname: customerData.firstname,
          lastname: customerData.lastname,
          email: customerData.email,
          telephone: customerData.telephone,
          cpf: customerData.cpf,
          address: {
            street: customerData.address_street,
            number: customerData.address_street_number,
            complement: customerData.address_street_complement,
            district: customerData.address_street_district,
            city: customerData.address_city,
            state: customerData.address_state,
            postcode: customerData.postcode,
          },
        },
        order: {
          freight_type: freightType,
          shipping_cost: shippingCost,
          products: cartItems.map((item) => ({
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            size: item.selectedSize,
          })),
        },
        paymentDetails: {
          paymentType: paymentMethod === "credit-card" ? "CreditCard" : "Pix",
          status: paymentData?.status || "pending",
          installments: creditCardData.installments || 1, // Inclui o número de parcelas
        },
        timestamp: new Date().toISOString(),
      };
  
      const orderRef = doc(db, "orders", String(orderData.order_id));
      await setDoc(orderRef, orderPayload);
  
      console.log("Venda salva com sucesso no Firestore!");
    } catch (error) {
      console.error("Erro ao salvar a venda no Firestore:", error);
    }
  };
  

  // Calcula a data e hora de expiração para 30 minutos após o horário atual
  const expirationDate = new Date();
  expirationDate.setMinutes(expirationDate.getMinutes() + 30);
  const formattedExpirationDate = expirationDate
    .toISOString()
    .replace("T", " ")
    .split(".")[0];

  const handlePayment = async () => {
    setLoading(true);

    try {
      let paymentResponse;
      if (paymentMethod === "pix") {
        console.log("Processando pagamento via Pix na Appmax...");
        paymentResponse = await fetch(
          "https://admin.appmax.com.br/api/v3/payment/pix",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              "access-token": "94F37DBC-8063ACAB-FDEE1F63-77900A06",
              cart: {
                order_id: orderData.order_id,
              },
              customer: {
                customer_id: orderData.customer_id,
              },
              payment: {
                pix: {
                  document_number: customerData.cpf,
                  expiration_date: formattedExpirationDate,
                },
              },
            }),
          }
        );

        const paymentDataResponse = await paymentResponse.json();
        if (!paymentResponse.ok || !paymentDataResponse.success) {
          throw new Error(
            paymentDataResponse.message || "Erro ao processar o pagamento."
          );
        }

        // Exibir os dados do Pix para o usuário
        setPixData({
          pix_qrcode: paymentDataResponse.data.pix_qrcode,
          pix_emv: paymentDataResponse.data.pix_emv,
          amount: calculateTotal(),
        });

        // Não salvar no Firestore ainda; aguardar o webhook
        console.log("Aguardando confirmação via webhook...");
      } else if (paymentMethod === "credit-card") {
        setPaymentModalVisible(true);
      }
    } catch (error) {
      console.error("Erro ao processar o pagamento:", error);
      alert("Erro ao processar o pagamento. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };
  const handleCreditCardPayment = async () => {
    setLoading(true);

    try {
      console.log("Processando pagamento via Cartão de Crédito na Appmax...");
      const paymentResponse = await fetch(
        "https://admin.appmax.com.br/api/v3/payment/credit-card",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            "access-token": "94F37DBC-8063ACAB-FDEE1F63-77900A06",
            cart: {
              order_id: orderData.order_id,
            },
            customer: {
              customer_id: orderData.customer_id,
            },
            payment: {
              CreditCard: {
                number: creditCardData.number,
                cvv: creditCardData.cvv,
                month: parseInt(creditCardData.month),
                year: parseInt(creditCardData.year),
                document_number: customerData.cpf,
                name: creditCardData.name,
                installments: parseInt(creditCardData.installments),
              },
            },
          }),
        }
      );

      const paymentDataResponse = await paymentResponse.json();
      console.log("Resposta da API de pagamento:", paymentDataResponse);
      if (!paymentResponse.ok || !paymentDataResponse.success) {
        throw new Error(
          paymentDataResponse.message || "Erro ao processar o pagamento."
        );
      }

      // Atualiza o estoque dos produtos no Firestore
      await updateProductStock(cartItems);

      // Salvar os dados no Firestore
      await saveOrderToFirestore(
        orderData,
        customerData,
        cartItems,
        paymentDataResponse
      );

      alert("Pagamento realizado com sucesso!");
      navigate("/inicio");
    } catch (error) {
      console.error("Erro ao processar o pagamento:", error);
      alert("Erro ao processar o pagamento. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checkout-container">
      <h2>Etapa {step}</h2>
      <div className="checkout-content">
        {step === 1 && (
          <div className="checkout-form">
            {/* Seção de Identificação */}
            <div className="section-identificacao">
              <h3>Identificação</h3>
              <input
                className="field-full"
                type="text"
                name="firstname"
                placeholder="Nome"
                value={customerData.firstname}
                onChange={handleInputChange}
              />
              <input
                className="field-full"
                type="text"
                name="lastname"
                placeholder="Sobrenome"
                value={customerData.lastname}
                onChange={handleInputChange}
              />
              <input
                className="field-full"
                type="email"
                name="email"
                placeholder="E-mail"
                value={customerData.email}
                onChange={handleInputChange}
              />
              <InputMask
                className="field-half"
                type="text"
                name="telephone"
                placeholder="Telefone"
                value={customerData.telephone}
                onChange={handleInputChange}
                mask="(99) 99999-9999"
              />
              <InputMask
                className="field-half"
                type="text"
                name="cpf"
                placeholder="CPF"
                value={customerData.cpf}
                onChange={handleInputChange}
                mask="999.999.999-99"
              />
            </div>

            {/* Seção de Entrega */}
            <div className="section-entrega">
              <h3>Entrega</h3>
              <InputMask
                className="field-half"
                type="text"
                name="postcode"
                placeholder="CEP"
                value={customerData.postcode}
                onChange={(e) => {
                  setCustomerData({
                    ...customerData,
                    postcode: e.target.value,
                  });
                }}
                mask="99999-999"
              />

              <input
                className="field-full"
                type="text"
                name="address_street"
                placeholder="Endereço"
                value={customerData.address_street}
                onChange={handleInputChange}
              />
              <input
                className="field-half"
                type="text"
                name="address_street_number"
                placeholder="Número"
                value={customerData.address_street_number}
                onChange={handleInputChange}
              />
              <input
                className="field-full"
                type="text"
                name="address_street_complement"
                placeholder="Complemento"
                value={customerData.address_street_complement}
                onChange={handleInputChange}
              />
              <input
                className="field-full"
                type="text"
                name="address_street_district"
                placeholder="Bairro"
                value={customerData.address_street_district}
                onChange={handleInputChange}
              />
              <input
                className="field-half"
                type="text"
                name="address_city"
                placeholder="Cidade"
                value={customerData.address_city}
                onChange={handleInputChange}
              />
              <input
                className="field-half"
                type="text"
                name="address_state"
                placeholder="Estado"
                value={customerData.address_state}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-action">
              <button onClick={handleNextStep} disabled={loading}>
                {loading ? "Processando..." : "Próximo"}
              </button>
            </div>
          </div>
        )}
{step === 2 && (
  <div className="shipping-info">
    <h3>Selecione o Frete:</h3>
    <div className="shipping-options">
      {isFreeShippingEligible() ? (
        <label>
          <input
            type="radio"
            name="freightType"
            value="standard"
            onChange={() => handleFreightTypeChange("standard")}
          />
          <span>Frete Grátis</span>
        </label>
      ) : (
        <>
          <label>
            <input
              type="radio"
              name="freightType"
              value="ouro-branco"
              onChange={() => handleFreightTypeChange("ouro-branco")}
            />
            <span>Ouro Branco - Frete Grátis</span>
          </label>
          <label>
            <input
              type="radio"
              name="freightType"
              value="standard"
              onChange={() => handleFreightTypeChange("standard")}
            />
            <span>
              Normal - R${" "}
              {calculateShippingCost(customerData.address_state, "standard")
                .toFixed(2)
                .replace(".", ",")}
            </span>
          </label>
          
          <label>
            <input
              type="radio"
              name="freightType"
              value="sedex"
              onChange={() => handleFreightTypeChange("sedex")}
            />
            <span>
              Sedex - R${" "}
              {calculateShippingCost(customerData.address_state, "sedex")
                .toFixed(2)
                .replace(".", ",")}
            </span>
          </label>
        </>
      )}
    </div>
    <button onClick={handleNextStep} disabled={loading || !freightType}>
      {loading ? "Processando..." : "Próximo"}
    </button>
  </div>
)}

        {step === 3 && (
          <div className="payment-info">
            <h3>Forma de Pagamento</h3>
            <div className="payment-options">
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="pix"
                  onChange={() => setPaymentMethod("pix")}
                />
                <span>Pix</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="credit-card"
                  onChange={() => setPaymentMethod("credit-card")}
                />
                <span>Cartão de Crédito</span>
              </label>
            </div>
            <button
              onClick={handlePayment}
              disabled={loading || !paymentMethod}
            >
              {loading ? "Processando..." : "Finalizar Pedido"}
            </button>

            {paymentModalVisible && paymentMethod === "credit-card" && (
              <div className="credit-card-modal">
                <h3>Informações do Cartão de Crédito</h3>
                <div className="credit-card-fields">
                  <input
                    className="field-full"
                    type="text"
                    name="number"
                    placeholder="Número do Cartão"
                    value={creditCardData.number}
                    onChange={handleCreditCardInputChange}
                  />
                  <input
                    className="field-half"
                    type="text"
                    name="cvv"
                    placeholder="CVV"
                    value={creditCardData.cvv}
                    onChange={handleCreditCardInputChange}
                  />
                  <input
                    className="field-half"
                    type="number"
                    name="month"
                    placeholder="Mês de Expiração (MM)"
                    value={creditCardData.month}
                    onChange={handleCreditCardInputChange}
                  />
                  <input
                    className="field-half"
                    type="number"
                    name="year"
                    placeholder="Ano de Expiração (AAAA)"
                    value={creditCardData.year}
                    onChange={handleCreditCardInputChange}
                  />
                  <input
                    className="field-full"
                    type="text"
                    name="name"
                    placeholder="Nome no Cartão"
                    value={creditCardData.name}
                    onChange={handleCreditCardInputChange}
                  />
                <select
  className="field-full"
  name="installments"
  value={creditCardData.installments}
  onChange={handleInstallmentsChange}
>
  {installmentOptions.map((option) => (
    <option key={option.installments} value={option.installments}>
      {option.installments}x de R$ {option.installmentValue}
    </option>
  ))}
</select>


                </div>
                <button onClick={handleCreditCardPayment} disabled={loading}>
                  {loading ? "Processando..." : "Confirmar Pagamento"}
                </button>
              </div>
            )}

            {pixData && paymentMethod === "pix" && (
              <div className="pix-payment-modal">
                <div className="pix-payment-info">
                  <h3>Pagamento via Pix</h3>
                  <p>Escaneie o QR code ou copie o código Pix abaixo:</p>
                  <div className="pix-qr-container">
                    <img
                      src={`data:image/png;base64,${pixData.pix_qrcode}`}
                      alt="QR Code Pix"
                    />
                  </div>
                  <div className="pix-copy-section">
                    <textarea
                      readOnly
                      value={pixData.pix_emv}
                      className="pix-code"
                    />
                    <button
                      onClick={() =>
                        navigator.clipboard.writeText(pixData.pix_emv)
                      }
                      className="copy-button"
                    >
                      Copiar Código
                    </button>
                  </div>
                  <p className="pix-amount">
                    Valor: R$ {pixData.amount.toFixed(2).replace(".", ",")}
                  </p>
                </div>
              </div>
            )}

            {/* Exibe a mensagem de confirmação e o botão de redirecionamento */}
            {paymentConfirmed && (
              <>
                <div className="modal-overlay"></div> {/* Sobreposição */}
                <div className="payment-success-message">
                  <p>Pagamento via Pix confirmado com sucesso!</p>
                  <button
                    onClick={() => navigate("/inicio")}
                    className="go-home-button"
                  >
                    Ir para o Início
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Resumo do Pedido */}
      <div className="order-summary">
        <h3>Resumo do Pedido</h3>
        {cartItems
          .filter((item) => item.quantity > 0)
          .map((item, index) => (
            <div key={index} className="order-item">
              <img
                src={item.imageUrls[0]}
                alt={item.name}
                className="item-image"
              />
              <p>{item.name}</p>
              <p>Tamanho: {item.selectedSize}</p> {/* Adicionado tamanho */}
              <p>{item.quantity}x</p>
              <p>R$ {item.price.toFixed(2).replace(".", ",")}</p>
            </div>
          ))}
        <div className="total">
        <p>Total: R$ {calculateTotalWithInterest().toFixed(2).replace(".", ",")}</p>

        </div>
      </div>
    </div>
  );
};

export default Checkout;
