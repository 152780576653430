import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Time.css";
import img1 from "./IMG/1.png";
import img2 from "./IMG/2.png";
import img3 from "./IMG/3.png";
import img4 from "./IMG/4.png";
import img5 from "./IMG/5.png";
import img6 from "./IMG/6.png";

const produtos = [
  { id: 1, src: img1, nome: "Vestidos", categoria: "vestido" },
  { id: 2, src: img2, nome: "Shorts", categoria: "short" },
  { id: 3, src: img3, nome: "Conjuntos", categoria: "conjunto" },
  { id: 5, src: img5, nome: "Macacão", categoria: "macacão" },
  { id: 6, src: img6, nome: "Camisas", categoria: "camisa" },
  { id: 4, src: img4, nome: "Sapatos", categoria: "sapato" },
];

const Time = () => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
    }
  }, []);

  const handleClick = (categoria) => {
    navigate(`/pesquisa?query=${categoria}`);
  };

  return (
    <div className="time-carousel-container">
      <div className="time-carousel" ref={carouselRef}>
        {produtos.map((produto) => (
          <div key={produto.id} className="produto-item" onClick={() => handleClick(produto.categoria)}>
            <img
              src={produto.src}
              alt={produto.nome}
              className="time-escudo"
            />
            <p>{produto.nome}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Time;