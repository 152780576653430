import React, { useEffect, useState } from 'react';

function TextoRotativo() {
  const [index, setIndex] = useState(0);
  const mensagens = [
    <> <strong>FRETE GRÁTIS!!</strong></>,
    <> <strong>PARCELE EM ATÉ 12X</strong></>,

  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % mensagens.length);
    }, 3000); // Troca de mensagem a cada 3 segundos

    return () => clearInterval(interval);
  }, [mensagens.length]);

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + mensagens.length) % mensagens.length);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % mensagens.length);
  };

  return (
    <div className="texto-rotativo">
      <button className="prev-button" onClick={handlePrev}>{'<'}</button>
      <h1>{mensagens[index]}</h1>
      <button className="next-button" onClick={handleNext}>{'>'}</button>
      <hr className="linha-branca" />
    </div>
  );
}

export default TextoRotativo;
